body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/** Placeholder */
::-webkit-input-placeholder {
  color: #aeaeae;
}

::-moz-placeholder {
  color: #aeaeae;
}

:-ms-input-placeholder {
  color: #aeaeae;
}

:-moz-placeholder {
  color: #aeaeae;
}

/** react-datepicker */
.react-datepicker__input-container {
  width: 120px;
}

.react-datepicker__input-container input {
  border: none;
  padding: 4px;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  padding-left: 22px;
  width: 100%;
  cursor: pointer;
  margin: -4px;
}

.react-datepicker__triangle {
  left: auto !important;
  right: 40px !important;
}

a {
  color: #ec7601;
}
